<template>
  <div>
    <h4
      v-if="title"
      class="text--secondary font-weight-regular">
      {{ title }}
    </h4>
    <v-slide-group
      :multiple="multiple"
      :mandatory="mandatory"
      :name="name"
      :color="color"
      show-arrows
      :value="selected"
      @change="handleChange">
      <v-slide-item
        v-for="option in options"
        :key="option.id"
        v-slot="{ active, toggle }"
        :value="option.id">
        <v-btn
          :input-value="active"
          active-class="info white--text"
          depressed
          @click="toggle">
          <span v-if="translationBase">
            {{ $t(`${translationBase}.${option[attribute]}`) }}
          </span>
          <span v-else>{{ option[attribute] }}</span>
        </v-btn>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
export default {
  name: "BtnGroupText",
  props: {
    title: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    selected: {
      type: [Number, Array],
      required: true,
    },
    name: {
      type: String,
      default: ""
    },
    translationBase: {
      type: String,
      required: false,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ""
    },
    attribute: {
      type: String,
      default: null
    },
    mandatory: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleChange(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style
  lang="scss"
  scoped>
</style>
