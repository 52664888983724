<template>
  <v-row
    align="center"
    justify="center">
    <v-col
      cols="12">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          {{ $t("views.sales.daily.title") }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              class="pa-3"
              cols="12"
              md="3">
              <menu-date-picker
                v-model="filters.date"
                :date-picker-options="{
                  allowedDates: this.allowedDates
                }"
                :input-options="{
                  'prepend-inner-icon': 'mdi-calendar',
                  'dense': true,
                  'hide-details': true,
                  'outlined': true,
                  'label': $t('fields.date')
                }" />
            </v-col>
            <v-col
              class="pa-3"
              cols="12"
              md="3">
              <v-autocomplete
                v-model="filters.zoneIds"
                :items="zones"
                item-text="label"
                item-value="value"
                :label="$t('fields.zones')"
                dense
                multiple
                outlined />
            </v-col>
            <v-col>
              <v-btn
                color="success"
                @click="getSales">
                {{ $t("actions.filter") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <btn-group-text
              :title="$t('views.sales.daily.filter')"
              :selected.sync="selectedSalesFilter"
              :options="filterOptions"
              name="winning_ticket_control"
              color="info"
              attribute="name"
              translation-base="views.sales.daily.filterOptions"
              mandatory
              @change="filterChanged" />
          </v-row>
          <br>
          <data-table
            class="stripped"
            :loading="loading"
            :headers="tableColumns"
            dense
            :items="effectiveSales">
            <template #item.initialBalance="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.totalSold="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.totalPrizes="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.jackpotCollectedForDay="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.netSale="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.totalJackpotPrizes="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.jackpot="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.upToDateBalance="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.purse="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.cashOuts="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.commission="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #item.netEarning="{ value }">
              {{ $formatter.asCurrency(value) }}
            </template>
            <template #body.append>
              <tr class="text-center">
                <th>Total</th>
                <th>-</th>
                <th>
                  {{ $formatter.asCurrency(sumField("initialBalance")) }}
                </th>
                <th>
                  {{ $formatter.asCurrency(sumField("totalSold")) }}
                </th>
                <th>
                  {{ $formatter.asCurrency(sumField("totalPrizes")) }}
                </th>
                <th v-if="showJackpot">
                  {{ $formatter.asCurrency(sumField("jackpotCollectedForDay")) }}
                </th>
                <th>
                  {{ $formatter.asCurrency(sumField("netSale")) }}
                </th>
                <th v-if="showJackpot">
                  {{ $formatter.asCurrency(sumField("totalJackpotPrizes")) }}
                </th>
                <th>
                  {{ $formatter.asCurrency(sumField("upToDateBalance")) }}
                </th>
                <th>
                  {{ $formatter.asCurrency(sumField("jackpot")) }}
                </th>
                <th>
                  {{ $formatter.asCurrency(sumField("purse")) }}
                </th>
                <th>
                  {{ $formatter.asCurrency(sumField("cashOuts")) }}
                </th>
                <th>
                  {{ $formatter.asCurrency(sumField("commission")) }}
                </th>
                <th>
                  {{ $formatter.asCurrency(sumField("netEarning")) }}
                </th>
              </tr>
            </template>
          </data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { getParams, getDailySales } from "@/api/sale";
import moment from "moment";
import { i18n } from "@/i18n";
import MenuDatePicker from "@/components/inputs/MenuDatePicker.vue";
import BtnGroupText from "../../components/inputs/BtnGroupText";
import DataTable from "@/components/DataTable.vue";

const WITHOUT_SALES = 0,
  WITH_SALES = 1,
  ALL = 2,
  filterOptions = [
    {
      id: WITHOUT_SALES,
      name: "withoutSales",
    },
    {
      id: WITH_SALES,
      name: "withSales",
    },
    {
      id: ALL,
      name: "all",
    },
  ];

function data() {
  return {
    sales: [],
    zones: [],
    showJackpot: false,
    filters: {
      date: moment().
      format("YYYY-MM-DD"),
      zoneIds: [],
    },
    loading: false,
    selectedSalesFilter: WITH_SALES,
    filterOptions,
    WITH_SALES,
    WITHOUT_SALES,
    ALL,
  };
}

export default {
  name: "Daily",
  components: {
    DataTable,
    MenuDatePicker,
    BtnGroupText,
  },
  data,
  computed: {
    tableColumns() {
      if (this.showJackpot) {
        return [
          {
            value: "bettingPool.reference",
            text: i18n.t("views.sales.daily.tableColumns.reference"),
            align: "center",
            sortable: true,
          },
          {
            value: "bettingPool.displayNumber",
            text: i18n.t("views.sales.daily.tableColumns.code"),
            align: "center",
            sortable: true,
          },
          {
            value: "initialBalance",
            text: i18n.t("views.sales.daily.tableColumns.initialBalance"),
            align: "center",
            sortable: true,
          },
          {
            value: "totalSold",
            text: i18n.t("views.sales.daily.tableColumns.totalSold"),
            align: "center",
            sortable: true,
          },
          {
            value: "totalPrizes",
            text: i18n.t("views.sales.daily.tableColumns.totalPrizes"),
            align: "center",
            sortable: true,
          },
          {
            value: "jackpotCollectedForDay",
            text: i18n.t("views.sales.daily.tableColumns.jackpotCollected"),
            align: "center",
            sortable: true,
          },
          {
            value: "netSale",
            text: i18n.t("views.sales.daily.tableColumns.netSale"),
            align: "center",
            sortable: true,
          },
          {
            value: "totalJackpotPrizes",
            text: i18n.t("views.sales.daily.tableColumns.totalJackpotPrizes"),
            align: "center",
            sortable: true,
          },
          {
            value: "upToDateBalance",
            text: i18n.t("views.sales.daily.tableColumns.poolBalance"),
            align: "center",
            sortable: true,
          },
          {
            value: "jackpot",
            text: i18n.t("views.sales.daily.tableColumns.jackpot"),
            align: "center",
            sortable: true,
          },
          {
            value: "purse",
            text: i18n.t("views.sales.daily.tableColumns.purse"),
            align: "center",
            sortable: true,
          },
          {
            value: "cashOuts",
            text: i18n.t("views.sales.daily.tableColumns.cashOuts"),
            align: "center",
            sortable: true,
          },
          {
            value: "commission",
            text: i18n.t("views.sales.daily.tableColumns.commission"),
            align: "center",
            sortable: true,
          },
          {
            value: "netEarning",
            text: i18n.t("views.sales.daily.tableColumns.netEarning"),
            align: "center",
            sortable: true,
          },
        ];
      }
      
      return [
        {
          value: "bettingPool.reference",
          text: i18n.t("views.sales.daily.tableColumns.reference"),
          align: "center",
          sortable: true,
        },
        {
          value: "bettingPool.displayNumber",
          text: i18n.t("views.sales.daily.tableColumns.code"),
          align: "center",
          sortable: true,
        },
        {
          value: "initialBalance",
          text: i18n.t("views.sales.daily.tableColumns.initialBalance"),
          align: "center",
          sortable: true,
        },
        {
          value: "totalSold",
          text: i18n.t("views.sales.daily.tableColumns.totalSold"),
          align: "center",
          sortable: true,
        },
        {
          value: "totalPrizes",
          text: i18n.t("views.sales.daily.tableColumns.totalPrizes"),
          align: "center",
          sortable: true,
        },
        {
          value: "netSale",
          text: i18n.t("views.sales.daily.tableColumns.netSale"),
          align: "center",
          sortable: true,
        },
        // Why is the jackpot column show anyway? in case they left some money there perhaps ?
        {
          value: "jackpot",
          text: i18n.t("views.sales.daily.tableColumns.jackpot"),
          align: "center",
          sortable: true,
        },
        {
          value: "commission",
          text: i18n.t("views.sales.daily.tableColumns.commission"),
          align: "center",
          sortable: true,
        },
        {
          value: "upToDateBalance",
          text: i18n.t("views.sales.daily.tableColumns.poolBalance"),
          align: "center",
          sortable: true,
        },
        {
          value: "purse",
          text: i18n.t("views.sales.daily.tableColumns.purse"),
          align: "center",
          sortable: true,
        },
        {
          value: "cashOuts",
          text: i18n.t("views.sales.daily.tableColumns.cashOuts"),
          align: "center",
          sortable: true,
        },
        {
          value: "netEarning",
          text: i18n.t("views.sales.daily.tableColumns.netEarning"),
          align: "center",
          sortable: true,
        },
      ];
    },
    effectiveSales() {
      switch (this.selectedSalesFilter) {
        case WITHOUT_SALES:
          return this.sales.filter(dailySaleEntry => dailySaleEntry["netSale"] === 0);
        case WITH_SALES:
          return this.sales.filter(dailySaleEntry => dailySaleEntry["netSale"] !== 0);
        case ALL:
          return this.sales;
        default:
          return [];
      }
    },
  },
  created() {
    getParams().
    then(response => {
      this.showJackpot = response.data.showJackpot;
      this.zones = response.data.zones;
      this.filters.zoneIds = this.zones.map(zone => zone.value);
    });
  },
  methods: {
    sumField(key) {
      return this.effectiveSales.reduce((a, b) => parseFloat(a) + parseFloat((b[key] || 0)), 0);
    },
    allowedDates(value) {
      const momentObj = moment(value, "YYYY-MM-DD").
      startOf("day");
      const tomorrowDate = moment().
      startOf("day").
      add(1, "days");
      
      return momentObj.isBefore(tomorrowDate);
    },
    getSales() {
      getDailySales(this.filters).
      then(response => {
        this.sales = response.data.sales;
      });
    },
    filterChanged(value) {
      this.selectedSalesFilter = value;
    },
  },
};
</script>

<style
  lang="scss"
  scoped>
::v-deep tr.text-center th {
  text-align: center !important;
}
</style>
